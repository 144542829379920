.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  text-align: left;
}

.App-header {
  padding:5%;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.App-article{
  max-width: 500px;
  margin: 0 auto;
  font:22px monospace;
}

.App-face {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

a{
  color:#fff5ee;
  background:#000;
  text-decoration:none;
  font-weight:700
}

h5{
  font-weight:500
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
